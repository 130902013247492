import { useMemo } from "react"
import { QueryClient } from "@tanstack/react-query"

import { useAccountNotifications } from "./react-query/queries/useAccountNotifications"
import { getProjectsData } from "../shared/utils"
import { NOTIFICATIONS } from "../shared/constants"
import {
  NotificationTypes,
  PDInviteExtraFieldsTypes,
} from "@/types/notifications"
import { ProgramType, ProjectListTypes } from "@/types/program"

const useInvitesData = (
  data: ProjectListTypes | undefined,
  accountId: string,
  queryClient: QueryClient
) => {
  const { data: notifications, isLoading: notificationsIsLoading } =
    useAccountNotifications<NotificationTypes[], Error>(queryClient, accountId)

  const invites = useMemo(() => {
    return notifications?.filter(
      (notification) => notification.key === NOTIFICATIONS.pd_invite
    )
  }, [notifications])

  const invitesData = useMemo(() => {
    return invites?.reduce(
      (acc: NotificationTypes[], notification: NotificationTypes) => {
        const project = getProjectsData(data).find(
          (project: ProgramType) =>
            project.id ===
            (notification.extra_fields as PDInviteExtraFieldsTypes)?.project_id
        )

        if (project) {
          acc.push({
            ...notification,
            extra_fields: {
              ...notification.extra_fields,
              image_url: project.image_url,
              landowner_status: project.landowner_status,
            } as PDInviteExtraFieldsTypes,
          })
        } else {
          acc.push({
            ...notification,
          })
        }

        return acc
      },
      []
    )
  }, [invites, data])

  return { invitesData, notificationsIsLoading }
}

export default useInvitesData
