import { Link, useNavigate, Navigate, useParams } from "react-router"
import { useQueryClient } from "@tanstack/react-query"

import MainLayout from "../_layouts/Main"
import AddAccountForm from "../../sections/AccountInfo/AccountInfoForm"
import { useAccount, useIsMultiAccount } from "../../hooks"
import useIsNew from "../../hooks/useIsNew"
import { AccountRoleType, AccountTypes } from "../../types/account"

export interface InitialAccountValuesTypes {
  account_name: string
  email: string
  first_name: string
  last_name: string
  phone_number: string
  role: AccountRoleType
}

const initialAccountValues: InitialAccountValuesTypes = {
  account_name: "",
  email: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  // When creating a new account, `bark_api` defaults role to "admin"
  //   This prefills it eagerly for ActionPermissionWrapper, https://app.asana.com/0/1199691870404858/1201200369286170/f
  role: "admin",
}

const AccountInfo = () => {
  const navigate = useNavigate()
  // DEV: Directly read `accountId` so it can be `null` for `/accounts/new`
  //   Alternatively, we could receive `isNew` as a prop, but this avoids weird cases like `accountId` and `isNew`
  const { accountId } = useParams()
  const isMultiAccount = useIsMultiAccount()
  const queryClient = useQueryClient()
  const isNew = useIsNew()
  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId as string, {
    enabled: !isNew,
  })

  function handleSuccess(account: AccountTypes) {
    // DEV: `account.id` doesn't exist above so we can't use `useAccountUrlPrefix`
    if (isNew) {
      navigate(`/accounts/${account.id}/onboarding/account-info`, {
        replace: true,
      })
    }
    navigate(`/accounts/${account.id}/onboarding/property-boundaries`)
  }

  // DEV: If someone lands on `/accounts/new` as an SA user, it will be very confusing to them
  //   Also allow viewing `/accounts/:id` version since it doesn't hurt anything except not using `_single`
  if (isNew && !isMultiAccount) {
    return <Navigate replace to="/" />
  }

  return (
    <MainLayout isLoading={isNew ? [] : [accountIsLoading]} contentBg="white">
      {/* DEV: Use `container` to set upper bound from container-perspective */}
      <div className="container">
        {/* DEV: Use `inner-container` inside `container` for secondary maximum (since both use `max-width`) */}
        <div className="inner-container mx-auto">
          <div className="mt-6 mb-4 md:mt-10 md:mb-8">
            <h3 className="text-2xl">Add an Account</h3>
          </div>
          <div className="mb-10 flex flex-col w-full md:flex-row md:flex-wrap">
            <div className="w-full">
              {isNew ? (
                <AddAccountForm
                  backLink={<Link to="/">Cancel</Link>}
                  initialValues={initialAccountValues}
                  onSuccess={handleSuccess}
                  page="onboarding"
                  submitText="Save and Continue"
                />
              ) : account ? (
                <AddAccountForm
                  backLink={<Link to={`/accounts/${account?.id}`}>Back</Link>}
                  initialValues={account}
                  onSuccess={handleSuccess}
                  page="onboarding"
                  submitText="Save and Continue"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default AccountInfo
