import { useEffect, useMemo } from "react"
import { findKey } from "lodash"
import { Location } from "react-router"

import { useGetAttestationQuizes } from "./react-query/queries/useGetAttestationQuizes"
import { useProjectsStore } from "../stores/useProjectsStore"
import { EligibilityQuizzes } from "@/types/eligibility"
import {
  EligibilityQuizCategoryType,
  ProgramCategoryType,
} from "@/types/constants"

const useQuizCategory = (accountId: string, location: Location) => {
  const { category, setCategory } = useProjectsStore()

  const { data: quizesData, isLoading: quizesIsLoading } =
    useGetAttestationQuizes<EligibilityQuizzes, Error>(accountId)

  const quizCategory = useMemo(() => {
    if (!quizesData) return undefined

    return findKey(
      quizesData,
      (d) => d.project_type === category
    ) as EligibilityQuizCategoryType
  }, [quizesData, category])

  useEffect(() => {
    if (location?.state?.quizProjectCategory !== undefined) {
      setCategory(location?.state?.quizProjectCategory as ProgramCategoryType)
    }
  }, [location?.state?.quizProjectCategory, setCategory])

  return { quizesData, quizCategory, quizesIsLoading }
}

export default useQuizCategory
