import { Link } from "react-router"

import SettingsLayout from "../_layouts/Settings"
import ChangePasswordForm from "../../sections/ChangePasswordForm"
import { Toast } from "../../components/Toast"

const ChangePassword = () => (
  <SettingsLayout title="Password">
    <ChangePasswordForm
      backLink={<Link to="/">Cancel</Link>}
      submitText="Change Password"
      onSuccess={() => {
        Toast.success("Password successfully changed!")
      }}
    />
  </SettingsLayout>
)

export default ChangePassword
