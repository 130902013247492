import { useQueryClient } from "@tanstack/react-query"
import { Link } from "react-router"

import SettingsLayout from "../_layouts/Settings"
import { useAccount, useAccountId, useAccountUrlPrefix } from "../../hooks"
import { AccountTypes } from "../../types/account"

const stripeDashboardLink: string = import.meta.env
  .VITE_APP_STRIPE_DASHBOARD_LINK
const SubscriptionManagement = () => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const accountUrlPrefix = useAccountUrlPrefix()

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  return (
    <SettingsLayout
      isLoading={[accountIsLoading]}
      title="Subscription Management"
      subtitle="NCX manages your membership payments through our payment partner Stripe."
    >
      {account?.is_member ? (
        <a
          href={stripeDashboardLink}
          target="_blank"
          rel="noopener noreferrer"
          className="manage-subscription link"
        >
          Manage Subscription
        </a>
      ) : (
        <Link
          to={`${accountUrlPrefix}/become-a-member`}
          className="become-a-member link"
        >
          Become a member
        </Link>
      )}
    </SettingsLayout>
  )
}

export default SubscriptionManagement
