import { RefObject, useEffect, useRef, useState } from "react"
import { useNavigate, useLocation } from "react-router"
import { useQueryClient } from "@tanstack/react-query"
import { DialogDisclosure, useDialogState } from "reakit/Dialog"

import SettingsLayout from "../_layouts/Settings"
import { Modal } from "../../components/Modal"
import { RowDataTypes, Table } from "../../components/Table"
import { Toast } from "../../components/Toast"
import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import { hasRequiredPermission } from "../../utils/hasRequiredPermission"
import UserForm from "../../sections/Users/UserForm"
import { useAccount, useAccountId, useAccountUsers } from "../../hooks"
import { HELP_CENTER_URL } from "../../shared/constants"
import { AccountUsers } from "../../types/accountUsers"
import { AccountTypes } from "../../types/account"
import { getUserColumns } from "../../sections/Users/getUserColumns"
import { genericErrMsg } from "../../api/auth"

// This file has a lot of strange edge cases so let's enumerate them
// (useful if we ever formalize this into testing in the future)
// - Adding users - Admin: allowed; non-admin: not allowed
// - Updating users - Admin: allowed; non-admin: not allowed
//   - Caveat: Admin cannot update a user if it means there will be no admins left on the account
//     i.e. Editing self, who is only admin, is disallowed
// - Removing users - Admin: allowed; non-admin: not allowed
//   - Caveat: If user is requester, then they can remove themselves from an account
//   - Caveat: Same "no admins left" caveat as updating (for both "user is requester" as well as normal "admin" behavior)
//   - Caveat: If user being removed is a single-account user, then they cannot be removed (since implies delete account/user entirely)
//      - Currently errors out from API, "good enough" edge case for now

const initialUserValues = {
  email: "",
  role: "",
}

const Users = () => {
  const tableRef = useRef<HTMLTableElement>(null)
  const location = useLocation()
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)
  const [editingModel, setEditingModel] = useState<RowDataTypes | null>(null)
  const isNew = !editingModel
  const initialOpenAddModal = location.state?.openAddModal
  const userDialog = useDialogState({
    animated: true,
    visible: initialOpenAddModal,
  })
  // DEV: `editingModelIsOnlyAdmin` will no longer function if we introduce pagination
  //   so handle that via a key on `/users` or `/account` or something
  // DEV: If we move to pagination, ensure that React Query properly handles it as well (currently doesn't)
  const { data: usersPage, isLoading: usersIsLoading } = useAccountUsers<
    AccountUsers,
    Error
  >(queryClient, accountId)

  const navigate = useNavigate()

  function handleSuccess(action: string, user?: RowDataTypes) {
    userDialog.hide()
    Toast.success(
      action === "save" && user
        ? isNew
          ? `You have added ${user.email} and they have been sent an email.`
          : `You have successfully updated ${user.email}.`
        : // DEV: We don't submit any `user` values to the server for "remove" so use model
          `You have successfully removed ${editingModel?.email} from this account.`
    )
    // If the user removed themselves, then go back to dashboard
    // DEV: Single-account users will be prevented by API from removing themselves
    if (action === "remove" && editingModel?.is_requester) {
      navigate("/")
    }
  }

  function handleError(error: Error) {
    userDialog.hide()
    if (typeof error === "object" && error !== null && "detail" in error) {
      Toast.error((error as { detail: string }).detail || genericErrMsg)
    } else {
      Toast.error(genericErrMsg)
    }
  }

  useEffect(() => {
    if (!userDialog.visible) {
      if (!userDialog.animating) {
        setEditingModel(null)
      }
    }
  }, [userDialog.animating, userDialog.visible])

  // DEV: This will no longer function if we introduce pagination
  // Account admins have to be actual users, not just invited as admins.
  const accountAdmins = usersPage?.object_list.filter(
    (user) => user.role === "admin" && user.account_user_type === "user"
  )
  const editingModelIsOnlyAdmin =
    accountAdmins?.length === 1 && accountAdmins[0] === editingModel

  // Determine form permissions
  let updateAllowed, deleteAllowed
  const dataLoaded = !!(account && usersPage)
  if (dataLoaded) {
    // Common starting point: Only allow update/remove from admins
    updateAllowed = hasRequiredPermission({
      action: "editUser",
      accountRole: account.role,
    })
    deleteAllowed = updateAllowed

    // Update caveat: If the model being edited is the only admin, then refuse saves
    updateAllowed = updateAllowed && !editingModelIsOnlyAdmin

    // Delete caveat: If user is requester, then they can remove themselves from an account
    deleteAllowed = deleteAllowed || editingModel?.is_requester

    // Delete caveat: Same "no admins left" caveat as updating (for both "user is requester" as well as normal "admin" behavior)
    deleteAllowed = deleteAllowed && !editingModelIsOnlyAdmin

    // Delete caveat: If user being removed is a single-account user, then they cannot be removed (since implies delete account/user entirely)
    // We don't stop/UI prompt this now, it's blocked by the API
  }

  return (
    <SettingsLayout
      isLoading={[accountIsLoading, usersIsLoading]}
      subtitle="Share access to this account with specific people"
      title="Users"
    >
      {dataLoaded && (
        <>
          <Table
            ref={tableRef as RefObject<HTMLDivElement>}
            bordered={false}
            columns={getUserColumns({
              account,
              accountId,
              userDialog,
              setEditingModel,
            })}
            data={usersPage.object_list}
          />

          <div className="mt-6">
            <ActionPermissionWrapper
              accountRole={account.role}
              action="editUser"
            >
              <DialogDisclosure
                {...userDialog}
                className="btn2 btn2-primary font-semibold"
              >
                Add User
              </DialogDisclosure>
            </ActionPermissionWrapper>
          </div>

          <Modal
            // DEV: Use bottom margin inside instead of padding as it's ignored when we need to scroll
            className="px-6 pt-6 pb-0 max-w-xl overflow-visible"
            closeMode="destroy"
            header={isNew ? "Add User" : "Edit User Settings"}
            aria-label={isNew ? "Add User" : "Edit User Settings"}
            dialog={userDialog}
          >
            <div className="mb-6">
              {editingModelIsOnlyAdmin && editingModel?.is_requester && (
                <div className="mt-3 mb-4 space-y-2">
                  <p>
                    You are the only admin of this account. To edit your
                    settings below, please ensure there is another admin. Have
                    questions? View our{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={HELP_CENTER_URL}
                      className="link"
                    >
                      Help Center
                    </a>
                    .
                  </p>
                </div>
              )}

              <UserForm
                backLink={
                  <DialogDisclosure {...userDialog}>Cancel</DialogDisclosure>
                }
                updateDisabled={!updateAllowed}
                deleteDisabled={!deleteAllowed}
                initialValues={editingModel || initialUserValues}
                isNew={isNew}
                onSuccess={handleSuccess}
                onError={handleError}
                submitText={isNew ? "Add User" : "Save Changes"}
              />
            </div>
          </Modal>
        </>
      )}
    </SettingsLayout>
  )
}

export default Users
