import { useMemo } from "react"
import { QueryClient } from "@tanstack/react-query"

import { useProjectList } from "./react-query/queries/useProjectList"
import { useProjectTypes } from "./react-query/queries/useProjectTypes"
import { useProjectsStore } from "../stores/useProjectsStore"
import {
  filterProjectsData,
  getProjectsData,
  getProjectTypeFilters,
} from "../shared/utils"
import { ProgramType, ProjectListTypes } from "@/types/program"
import { ProgramTypes } from "@/sections/ProjectListing/types"
import { FilterType } from "@/sections/ProjectListing/CategoryFilter/CategoryFilter"

const useProjectListingData = (
  sortBy: string,
  accountId: string,
  queryClient: QueryClient
) => {
  const {
    category,
    govtProjectsOn,
    ineligibleProjectsOn,
    hasLandownerCost,
    termLengthFilter,
    paymentTypes,
    searchProjects,
  } = useProjectsStore()

  const { data, isLoading: projectListIsLoading } = useProjectList<
    ProjectListTypes,
    Error
  >(queryClient, accountId, {})

  const { data: projectTypes, isLoading: projectTypesIsLoading } =
    useProjectTypes<ProgramTypes, Error>(queryClient, accountId, {})

  const projectTypeFilters: FilterType[] = useMemo(() => {
    return getProjectTypeFilters(projectTypes, category)
  }, [projectTypes, category])

  const projectsData: ProgramType[] = useMemo(() => {
    return (
      filterProjectsData(
        getProjectsData(data as ProjectListTypes),
        sortBy,
        category,
        govtProjectsOn,
        ineligibleProjectsOn,
        hasLandownerCost,
        termLengthFilter,
        paymentTypes,
        searchProjects
      ) || []
    )
  }, [
    data,
    sortBy,
    category,
    govtProjectsOn,
    ineligibleProjectsOn,
    hasLandownerCost,
    termLengthFilter,
    paymentTypes,
    searchProjects,
  ])

  const projectsDataIneligibleOn = useMemo(() => {
    return filterProjectsData(
      getProjectsData(data),
      sortBy,
      category,
      govtProjectsOn,
      true,
      hasLandownerCost,
      termLengthFilter,
      paymentTypes,
      searchProjects
    )
  }, [
    data,
    sortBy,
    category,
    govtProjectsOn,
    hasLandownerCost,
    termLengthFilter,
    paymentTypes,
    searchProjects,
  ])

  const projectsDataIneligibleOnLength: number =
    projectsDataIneligibleOn?.length

  return {
    data,
    projectListIsLoading: projectListIsLoading || projectTypesIsLoading,
    projectsData,
    projectsDataIneligibleOnLength,
    projectTypes,
    projectTypeFilters,
  }
}

export default useProjectListingData
