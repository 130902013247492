import { useState, useEffect } from "react"
import { useNavigate } from "react-router"

import { Toast } from "../components/Toast"

export const useIsCheckoutSuccess = () => {
  const navigate = useNavigate()
  const [isCheckoutSuccess, setIsCheckoutSuccess] = useState<boolean>(false)

  // DEV: Check if the URL contains the stripe "checkout-success" query parameter, and remove it.
  useEffect(() => {
    const url = new URL(window.location.href)
    const searchParams = url.searchParams

    if (searchParams.has("checkout-success")) {
      searchParams.delete("checkout-success")

      const newUrl = `${url.pathname}${
        searchParams.toString() ? `?${searchParams.toString()}` : ""
      }`

      window.history.replaceState({}, document.title, newUrl)

      setIsCheckoutSuccess(true)

      Toast.success("Congrats! Your NCX purchase was successful.")
    }
  }, [navigate])

  return isCheckoutSuccess
}
