import { useMemo } from "react"
import { useQueryClient } from "@tanstack/react-query"
import _ from "lodash"

import { useAccountId } from "./react-query/utils"
import { useProjectList } from "./react-query/queries/useProjectList"
import { getProjectsData } from "../shared/utils"
import { ProjectDataTypes, ProjectListTypes } from "@/types/program"

const useSponsoredPrograms = () => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()

  const { data: projectList, isLoading: projectListIsLoading } = useProjectList<
    ProjectListTypes,
    Error
  >(queryClient, accountId, {})

  const programsData: ProjectDataTypes[] = useMemo(() => {
    return getProjectsData(projectList)
  }, [projectList])

  const sponsoredPrograms: ProjectDataTypes[] = useMemo(() => {
    return _.shuffle(programsData.filter((program) => program.is_sponsored))
  }, [programsData])

  return { projectList, projectListIsLoading, sponsoredPrograms }
}

export default useSponsoredPrograms
