import { Link, useNavigate } from "react-router"
import { useQueryClient } from "@tanstack/react-query"

import SettingsLayout from "../_layouts/Settings"
import AccountInfoForm from "../../sections/AccountInfo/AccountInfoForm"
import { Toast } from "../../components/Toast"
import { useAccount, useAccountAbsoluteUrl, useAccountId } from "../../hooks"
import { AccountTypes } from "../../types/account"

const AccountInfo = () => {
  const accountId = useAccountId()
  const accountUrl = useAccountAbsoluteUrl()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  return (
    <SettingsLayout isLoading={[accountIsLoading]} title="Account Information">
      {account && (
        <AccountInfoForm
          initialValues={account}
          backLink={<Link to={accountUrl}>Cancel</Link>}
          submitText="Save Changes"
          onSuccess={() => {
            Toast.success("Account information updated")
            navigate(accountUrl)
          }}
          page="settings"
        />
      )}
    </SettingsLayout>
  )
}

export default AccountInfo
