import { ReactNode, RefObject } from "react"
import assert from "assert"
import cx from "classnames"

export interface RowDataTypes {
  first_name?: string
  last_name?: string
  id: string
  email: string
  is_requester: boolean
  role: string
  [key: string]: any
}

interface ColumnTypes {
  title?: string
  getValue: (row: RowDataTypes) => ReactNode
  className?: string | ((row: RowDataTypes) => string)
  headerClassName?: string
}

interface TableTypes {
  bordered?: boolean
  className?: string
  columns: Record<string, ColumnTypes>
  data: any[]
  footer?: ReactNode
  isHeaderVisible?: boolean
  noDataText?: string
  onRowClick?: (row: RowDataTypes) => void
  rowClassName?: string | ((row: RowDataTypes) => string)
  ref: RefObject<HTMLDivElement>
}

interface TableContainerTypes {
  ref: RefObject<HTMLDivElement>
  bordered?: boolean
  className?: string
  children: ReactNode
}

interface NoRecordsRowTypes {
  columns: Record<string, ColumnTypes>
  noDataText: string
}

interface TableComponentTypes {
  className?: string
  children: ReactNode
}

interface TableRowTypes extends TableComponentTypes {
  onClick?: () => void
}

export const NoRecordsRow = ({ columns, noDataText }: NoRecordsRowTypes) => (
  <tr>
    <td
      className="py-4 text-center text-sm"
      colSpan={Object.keys(columns).length}
    >
      {noDataText}
    </td>
  </tr>
)

// DEV: All these classes are very excessive usually, but sometimes practical for dynamic forms within tables
//   e.g. SellingProcessStep3
export const TableContainer = ({
  bordered = true,
  className,
  ref,
  children,
}: TableContainerTypes) => (
  <div
    className={cx(
      "overflow-x-scroll  md:overflow-x-auto",
      bordered && "border border-cloud rounded",
      className
    )}
    ref={ref}
  >
    <table className="min-w-max-content md:min-w-full divide-y divide-cloud w-full">
      {children}
    </table>
  </div>
)

export const TableHeadRow = ({ className, children }: TableComponentTypes) => (
  <thead className={cx("text-dusk text-sm text-left leading-4", className)}>
    <tr>{children}</tr>
  </thead>
)

export const TableHeadCell = ({ className, children }: TableComponentTypes) => (
  <th
    className={cx(
      // DEV: Be careful of overpadding columns,
      // we need thin padding for 800px SellingProcessStep3 with add row
      "p-3 pb-3 font-semibold lg:p-5 lg:pb-4",
      className
    )}
  >
    {children}
  </th>
)

export const TableBody = ({ className, children }: TableComponentTypes) => (
  <tbody className={cx("divide-y divide-cloud text-carbon", className)}>
    {children}
  </tbody>
)

export const TableRow = ({ className, onClick, children }: TableRowTypes) => (
  <tr className={className} onClick={onClick}>
    {children}
  </tr>
)

export const TableCell = ({ className, children }: TableComponentTypes) => (
  <td
    className={cx(
      // DEV: Be careful of overpadding columns,
      //   we need thin padding for 800px SellingProcessStep3 with add row
      "px-3 py-3  lg:px-5 lg:py-4",
      className
    )}
  >
    {children}
  </td>
)

export const TableFoot = ({ className, children }: TableComponentTypes) => (
  <tfoot className={className}>{children}</tfoot>
)

export const Table = ({
  bordered = true,
  className,
  columns,
  data,
  footer = null,
  isHeaderVisible = true,
  noDataText = "No records found",
  onRowClick = () => {},
  rowClassName = "",
  ref,
}: TableTypes) => {
  assert(
    ["string", "function"].includes(typeof rowClassName),
    "`rowClassName` must be string or function whose return value will be passed to `cx`"
  )

  return (
    <TableContainer ref={ref} className={className} bordered={bordered}>
      {isHeaderVisible && (
        <TableHeadRow>
          {Object.entries(columns).map(([id, column]) => (
            <TableHeadCell className={column.headerClassName} key={id}>
              {column.title}
            </TableHeadCell>
          ))}
        </TableHeadRow>
      )}

      <TableBody>
        {data.length > 0 ? (
          data.map((row: RowDataTypes, index: number) => (
            <TableRow
              className={cx(
                typeof rowClassName === "function"
                  ? rowClassName(row)
                  : rowClassName
              )}
              key={`row-${row.id || index}`}
              onClick={() => onRowClick(row)}
            >
              {Object.entries(columns).map(([id, column]) => (
                <TableCell
                  className={cx(
                    typeof column.className === "function"
                      ? column.className(row)
                      : column.className
                  )}
                  key={`${row.id || index}-${id}`}
                >
                  {column.getValue(row)}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <NoRecordsRow columns={columns} noDataText={noDataText} />
        )}
      </TableBody>

      {footer && <TableFoot>{footer}</TableFoot>}
    </TableContainer>
  )
}
