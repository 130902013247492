import { useState, useEffect } from "react"
import { useParams } from "react-router"

import MainLayout from "./_layouts/Main"
import EligibilityQuizStart from "../sections/EligibilityQuiz/EligibilityQuizStart"
import {
  useAccountId,
  useGetAttestationQuizes,
  useGetAttestationQuiz,
} from "../hooks"
import EligibilityQuizSteps from "../sections/EligibilityQuiz/EligibilityQuizSteps"
import { EligibilityQuizCategoryType } from "@/types/constants"
import {
  AttestationQuizesTypes,
  AttestationQuizTypes,
} from "@/types/eligibilityQuiz"

const EligibilityQuiz = () => {
  const accountId = useAccountId()
  const { quizCategory } = useParams()
  const [quizStep, setQuizStep] = useState<number | undefined>(undefined)

  const { data: quizesData, isLoading: quizesIsLoading } =
    useGetAttestationQuizes<AttestationQuizesTypes, Error>(accountId)

  const { data: quizData, isLoading: quizIsLoading } = useGetAttestationQuiz<
    AttestationQuizTypes,
    Error
  >(
    accountId,
    quizCategory as EligibilityQuizCategoryType,
    (quizesData &&
      quizesData[quizCategory as keyof typeof quizesData]
        ?.completed) as boolean,
    {
      enabled: quizesData !== undefined,
    }
  )

  useEffect(() => {
    setQuizStep(undefined)
  }, [quizCategory])

  return (
    <MainLayout isLoading={[quizesIsLoading, quizIsLoading]} contentBg="white">
      {quizStep === undefined ? (
        <EligibilityQuizStart
          categoryClass={quizCategory as EligibilityQuizCategoryType}
          projectType={quizData?.quiz_data.project_type}
          startQuiz={() => setQuizStep(0)}
          questionsNum={quizData?.attestations.length}
        />
      ) : (
        <EligibilityQuizSteps
          quizesData={quizesData}
          attestations={quizData?.attestations}
          quizData={quizData?.quiz_data}
          quizStep={quizStep}
          setQuizStep={setQuizStep}
        />
      )}
    </MainLayout>
  )
}

export default EligibilityQuiz
