import { ReactNode } from "react"
import { NavLink } from "react-router"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"

import { useAccountId, useIsMultiAccount, useProfile } from "../../hooks"
import { SubNavigation } from "../../components/SubNavigation"
import MainLayout from "./Main"
import { Profile } from "@/types"

interface SettingsTypes {
  children: ReactNode
  title: string
  subtitle?: string
  isLoading?: boolean | boolean[]
}

const singleAccountNavItems = (profile: Profile | undefined) => [
  {
    label: "Contact Information",
    link: "/settings/contact-info",
  },
  {
    label: "Password",
    link: "/settings/change-password",
    shouldShow: !!profile?.has_updatable_password,
  },
  {
    label: "Program Interest",
    link: "/settings/program-interest",
  },
  {
    label: "Property Details",
    link: "/settings/property-details",
  },
  {
    label: "Subscription Management",
    link: "/settings/subscription-management",
  },
  {
    label: "Users",
    link: `/settings/users`,
  },
]

const multiAccountPersonalNavItems = [
  {
    label: "Contact Information",
    link: "/settings/contact-info",
  },
  {
    label: "Password",
    link: "/settings/change-password",
  },
]

const multiAccountNavItems = (accountId: string) => [
  {
    label: "Account Information",
    link: `/accounts/${accountId}/settings/account-info`,
  },
  {
    label: "Property Details",
    link: `/accounts/${accountId}/settings/property-details`,
  },
  {
    label: "Subscription Management",
    link: `/accounts/${accountId}/settings/subscription-management`,
  },
  {
    label: "Users",
    link: `/accounts/${accountId}/settings/users`,
  },
]

const Settings = ({ children, subtitle, title, isLoading }: SettingsTypes) => {
  const isMultiAccount = useIsMultiAccount()
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const isAccountSettings = accountId !== "_single"
  const { data: profile } = useProfile<Profile, Error>(queryClient)

  // If we're on a specific account page, then we're in account settings
  let navigationItems, subNavigation

  if (isAccountSettings) {
    subNavigation = (
      <SubNavigation
        title="Account Settings"
        aria-label="Back to account"
        backUrl={`/accounts/${accountId}`}
      />
    )
    navigationItems = multiAccountNavItems(accountId)
    // Otherwise, we're in personal settings
  } else {
    subNavigation = (
      <SubNavigation
        // Labeled as "Account settings" for single-account users since their whole world is this account
        title={isMultiAccount ? "Personal Settings" : "Account Settings"}
        aria-label="Back to dashboard"
        backUrl="/"
      />
    )
    navigationItems = isMultiAccount
      ? multiAccountPersonalNavItems
      : singleAccountNavItems(profile)
  }

  // Return our layout
  return (
    <MainLayout isLoading={isLoading ?? []} contentBg="white">
      {subNavigation}

      {/* DEV: Use `container` to set upper bound from container-perspective */}

      <div className="container max-w-1120 px-5">
        {/* DEV: Use `inner-container` inside `container` for secondary maximum (since both use `max-width`) */}
        <div className="inner-container mx-auto mt-6 mb-6 md:mt-12 md:mb-8">
          {/* Always: Pad to bottom so we're not super short */}
          <div className="mb-10">
            <div className="grid grid-cols-1 gap-10 mb-10  md:gap-4 md:mb-0 md:w-full md:grid-cols-3">
              <ul className="-m-3 md:m-0 md:col-span-1 lg:-m-3">
                {navigationItems.map((item) => (
                  <NavLink
                    to={item.link}
                    className={({ isActive }) =>
                      cx("settings-nav-link", {
                        "settings-nav-link-active": isActive,
                      })
                    }
                    key={item.link}
                  >
                    {item.label}
                  </NavLink>
                ))}
                <hr className="h-px bg-haze border-0 mt-4 md:hidden" />
              </ul>

              <div className="md:col-span-2">
                <div className="mb-6">
                  <div className="flex justify-between">
                    <h3 className="text-carbon text-xl">{title}</h3>
                  </div>

                  {subtitle && <p className="mt-1">{subtitle}</p>}
                </div>

                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Settings
