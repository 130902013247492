import { Link } from "react-router"
import { useQueryClient } from "@tanstack/react-query"

import SettingsLayout from "../_layouts/Settings"
import ContactInfoForm from "../../sections/ContactInfoForm"
import { useProfile } from "../../hooks"
import { Toast } from "../../components/Toast"
import { Profile } from "../../types"

const ContactInfo = () => {
  const queryClient = useQueryClient()
  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient)

  return (
    <SettingsLayout isLoading={profileIsLoading} title="Contact Information">
      {profile && (
        <ContactInfoForm
          initialValues={profile}
          showVerificationStatus={true}
          backLink={<Link to="/">Cancel</Link>}
          submitText="Save Changes"
          onSuccess={() => {
            Toast.success("Contact information updated")
          }}
        />
      )}
    </SettingsLayout>
  )
}

export default ContactInfo
